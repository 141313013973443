<template>
  <v-dialog v-model="open" persistent max-width="600">
    <v-card>
      <v-card-title class="mb-3">
        {{ dialog.type === "create" ? "新增格主" : "編輯格主" }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert type="info" dense>
              已驗證名字和電話的使用者才能新增為格主
            </v-alert>
            <v-select
              v-if="dialog.type === 'create'"
              v-model="payload.userID"
              :items="userOptions"
              label="格主"
              no-data-text="沒有可新增為格主的使用者"
              outlined
              clearable
            ></v-select>
            <v-text-field
              v-else
              v-model="payload.userID"
              :items="userOptions"
              label="格主"
              outlined
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="payload.ichibanIds"
              :items="ichibanOptions"
              label="一番賞"
              outlined
              hide-details
              clearable
              multiple
              chips
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="open = false" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialog.type === 'create'"
          text
          color="primary"
          @click="create"
          class="bt-text-large"
        >
          建立
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="update"
          class="bt-text-large"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogOwner",
  props: ["value", "dialog", "owner", "users", "ichibans", "owners"],
  data: () => ({
    amount: null,
    payload: {
      userID: "",
      ichibanIds: [],
    },
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userOptions() {
      return this.users
        .filter((user) => {
          return !this.owners.find((owner) => owner.userID === user.userID);
        })
        .filter((user) => user.name && user.phone)
        .map((user) => {
          // return user.userID;
          return {
            text: `${user.userID} - ${user.name} - ${user.phone}`,
            value: user.userID,
          };
        });
    },
    ichibanOptions() {
      return this.ichibans
        .filter((ichiban) => ichiban.isOnline && ichiban.isAvailable)
        .map((ichiban) => {
          return {
            text: `${ichiban.key} - ${ichiban.name}`,
            value: ichiban._id,
          };
        });
    },
  },
  created() {
    if (this.owner) {
      // this.payload = _.cloneDeep(this.category);
      this.payload.userID = this.owner.userID;
      this.payload.ichibanIds = this.owner.ichibanIds;
    }
  },
  methods: {
    async create() {
      if (!this.payload.userID) {
        this.$toast.error("請選擇使用者");
        return;
      }

      try {
        this.$vloading.show();
        await this.axios.post(`/ichibanOwner`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "建立失敗");
      }
      this.$vloading.hide();
    },
    async update() {
      this.$vloading.show();

      try {
        await this.axios.put(`/ichibanOwner/${this.owner.userID}`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response?.data?.message || "編輯失敗");
      }
      this.$vloading.hide();
    },
  },
};
</script>
