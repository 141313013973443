<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      格主列表

      <!-- <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        新增格主
      </v-btn> -->
    </h3>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" sm="3">
        <v-text-field
          label="關鍵字"
          placeholder="關鍵字"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <!-- <v-col cols="6" sm="3" v-if="accessBranch.length > 1">
        <v-select
          label="店點"
          placeholder="店點"
          v-model="filterQuery.branch"
          :items="branches"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:item.ichibans="{ item }">
            <template v-if="item.ichibans.length > 0">
              <div v-for="ichiban in item.ichibans" :key="ichiban._id">
                <div>{{ ichiban.key }} -- {{ ichiban.name }}</div>
              </div>
            </template>
            <div v-else>無</div>
          </template>
          <template v-slot:item.status="{ item }">
            <template v-if="item.ichibans.length > 0">
              <div v-for="ichiban in item.ichibans" :key="ichiban._id">
                售出：{{ ichiban.sold }} / 金額：{{ ichiban.amount }}
              </div>
            </template>
            <div v-else>---</div>
          </template>
          <!-- <template v-slot:item.action="{ item }">
            <div class="my-1">
              <v-btn
                class="my-1 mx-2"
                color="primary"
                @click="openDialog('edit', item)"
              >
                編輯
              </v-btn>
            </div>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <DialogOwner
      v-if="dialog.open"
      v-model="dialog.open"
      :dialog="dialog"
      :ichibans="ichibans"
      :users="users"
      :owners="owners"
      :owner="selectedOwner"
      @load="load"
    /> -->
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import DialogOwner from "@/components/dashboard/ichiban/DialogOwner.vue";

export default {
  name: "Owners",
  mixins: [util],
  components: { DialogOwner },
  data: () => ({
    dayjs,
    payload: {
      userID: "",
      ichibanIds: [],
    },
    users: [],
    owners: [],
    ichibans: [],
    dialog: { type: "create", open: false },
    menu: false,
    filterQuery: {
      date: [],
      keyword: "",
      branch: "",
    },
    selectedOwner: null,
    headers: [
      // {
      //   text: "日期",
      //   value: "createdAt",
      //   align: "center",
      //   sortable: true,
      //   sort: (a, b) => {
      //     if (dayjs(a.date).isAfter(dayjs(b.date))) {
      //       return -1;
      //     }
      //     if (dayjs(a.date).isBefore(dayjs(b.date))) {
      //       return 1;
      //     }
      //     return 0;
      //   },
      // },
      {
        text: "帳號",
        value: "userID",
        align: "center",
        sortable: false,
      },
      {
        text: "名字",
        value: "user.name",
        align: "center",
        sortable: false,
      },
      {
        text: "電話",
        value: "user.phone",
        align: "center",
        sortable: false,
      },
      {
        text: "一番賞",
        value: "ichibans",
        align: "left",
        sortable: false,
      },
      {
        text: "售出狀況",
        value: "status",
        align: "left",
        sortable: false,
      },
      // {
      //   text: "編輯",
      //   value: "action",
      //   align: "center",
      //   sortable: false,
      // },
    ],
  }),
  computed: {
    _: () => _,
    mapUser() {
      return _.keyBy(this.users, "_id");
    },
    formattedDate() {
      const date = this.filterQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },

    filterData() {
      const { keyword } = this.filterQuery;

      let owners = this.owners;
      if (keyword) {
        owners = owners.filter((owner) =>
          owner.userID.toUpperCase().includes(keyword.toUpperCase())
        );
      }
      // if (branch) {
      //   data = data.filter((trade) => trade.branch === branch);
      // }

      return owners;
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();
      this.getUsers();
      this.getIchiban();
      await this.getOwners();
      this.$vloading.hide();
    },
    clear() {
      this.dialog.open = false;
    },
    openDialog(type, payload) {
      this.selectedOwner = payload;
      this.dialog.type = type;
      this.dialog.open = true;
    },
    async getUsers() {
      const { data } = await this.axios.get("/users", {
        params: { store: "@695yimoc" },
      });
      this.users = data.sort(this.sortDateDesc("createdAt"));
    },
    async getIchiban() {
      const { data } = await this.axios.get(`/ichiban`);
      this.ichibans = data;
    },
    async getOwners() {
      const { data } = await this.axios.get("/ichibanOwner");
      this.owners = data;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.dates-input {
  min-width: 150px;
}
</style>
